import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import { renewBearerWithRefreshTokenAndNavigateToPath } from '@/services/core/authService.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { noAuthRequired: true },
    component: () =>
      import(/*webpackChunkName: "login"*/ '@/views/core/Login.vue'),
  },
  //region CRM
  {
    path: '/crm',
    name: 'CRM',
    component: () =>
      import(/* webpackChunkName: "crm" */ '@/views/crm/CRM.vue'),
  },
  {
    path: '/crm/addresses',
    name: 'Adressen',
    redirect: { name: 'CRM' },
    meta: { title: 'Adressen', linkable: false },
  },
  {
    path: '/crm/addresses/new',
    name: 'crmNewAddress',
    meta: { title: 'Neue Adresse' },
    component: () => import('@/views/crm/addresses/AddAddress.vue'),
  },
  {
    path: '/crm/addresses/:lfdnr',
    name: 'crmAddress',
    meta: { title: 'Adresse' },
    props: (route) => {
      const lfdnr = Number.parseInt(route.params.lfdnr, 10)
      if (Number.isNaN(lfdnr)) {
        return 0
      }
      return { lfdnr, tab: route.query.tab || null }
    },
    component: () =>
      import(
        /* webpackChunkName: "crmAddress" */ '@/views/crm/addresses/Address.vue'
      ),
  },
  {
    path: '/crm/addresses/:lfdnr/documents',
    name: 'crmAddressDocuments',
    redirect: { name: 'Dokumente' },
    meta: { title: 'Dokumente', linkable: false },
  },
  {
    path: '/crm/addresses/:lfdnr/documents/:wf_cid',
    name: 'crmAddressDocumentDetailed',
    meta: { title: 'Dokument - ' },
    props: (route) => {
      const wf_cid = route.params.wf_cid
      const lfdnr = Number.parseInt(route.params.lfdnr, 10)
      if (Number.isNaN(lfdnr)) {
        return 0
      }
      return { lfdnr, wf_cid }
    },
    component: () =>
      import(
        /* webpackChunkName: "crmAddressDocumentDetailed" */ '@/views/crm/addresses/AddressDocumentDetailedRedirect.vue'
      ),
  },
  //endregion
  //region Calendar
  {
    path: '/calv2',
    name: 'Kalender',
    meta: { version: '✨ v2' },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ '@/views/calendar/Calendar.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (
        store.getters.settings_cal !== undefined &&
        store.getters.locations !== undefined &&
        store.getters.scopes !== undefined
      ) {
        next()
      } else {
        next({
          path: '/calv2/loading',
          query: to.query,
        })
      }
    },
  },
  {
    path: '/calv2/loading',
    name: 'Calender-v2 Loading',
    meta: { title: 'Kalender' },
    component: () =>
      import(/* webpackChunkName: "calendar" */ '@/views/calendar/Loading.vue'),
  },
  //endregion
  //region Statistics
  {
    path: '/statistics',
    meta: { title: 'Statistik', linkable: false },
  },
  {
    path: '/statistics/revenue',
    name: 'revenueStatistics',
    meta: { title: 'Umsatzstatistik' },
    component: () => import('@/views/statistics_reloaded/revenue/Revenue.vue'),
  },
  {
    path: '/statistics/customer',
    name: 'customerStatistics',
    meta: { title: 'Kundenstatistik' },
    component: () =>
      import('@/views/statistics_reloaded/customer/Customer.vue'),
  },
  {
    path: '/statistics/distributor',
    name: 'distributorStatistics',
    meta: { title: 'Lieferantenstatistik' },
    component: () =>
      import('@/views/statistics_reloaded/distributor/Distributor.vue'),
  },
  {
    path: '/statistics/document',
    name: 'documentStatistic',
    meta: { title: 'Dokumentstatistik' },
    component: () =>
      import('@/views/statistics_reloaded/document/Document.vue'),
  },
  {
    path: '/statistics/warehouse',
    name: 'warehouseStatistic',
    meta: { title: 'Lagerstatistik' },
    component: () =>
      import('@/views/statistics_reloaded/warehouse/Warehouse.vue'),
  },
  {
    path: '/statistics/customerfrequency',
    name: 'customerFrequencyStatistic',
    meta: { title: 'Kundenfrequenzstatistik' },
    component: () =>
      import(
        '@/views/statistics_reloaded/customerfrequency/Customerfrequency.vue'
      ),
  },
  //endregion
  //region Old statistics
  {
    path: '/bi/revenue',
    name: 'Umsatzstatistik',
    meta: { title: 'Statistik - Umsatz' },
    component: () =>
      import(/* webpackChunkName: "Revenue" */ '@/views/bi/Revenue.vue'),
  },
  {
    path: '/bi/keyfigures',
    name: 'Kennzahlen',
    meta: { title: 'Statistik - Kennzahlen' },
    component: () =>
      import(/* webpackChunkName: "Keyfigures" */ '@/views/bi/KeyFigures.vue'),
  },
  {
    path: '/statistics/distributors/:lfdnr/overallChart',
    name: 'ekRevenueOverallChart',
    meta: { title: 'EK-Statistik: Gesamt Verlauf' },
    component: () => import('@/views/statistics/ekRevenue/Overall.vue'),
  },
  {
    path: '/statistics/distributors/:lfdnr/typesChart/:articleTypeCode',
    name: 'ekRevenueTypesChart',
    meta: { title: 'EK-Statistik: Artikeltyp Verlauf' },
    component: () => import('@/views/statistics/ekRevenue/ArticleTypes.vue'),
  },
  {
    path: '/statistics/distributors/:lfdnr/typesChart/:articleTypeCode/categoriesChart/:articleCategoryCode',
    name: 'ekRevenueCategoriesChart',
    meta: { title: 'EK-Statistik: Artikelkategorie Verlauf' },
    component: () =>
      import('@/views/statistics/ekRevenue/ArticleCategories.vue'),
  },
  {
    path: '/statistics/distributors/:lfdnr/typesChart/:articleTypeCode/categoriesChart/:articleCategoryCode/articlesChart/:articleCode',
    name: 'ekRevenueArticlesChart',
    meta: { title: 'EK-Statistik: Artikel Verlauf' },
    component: () => import('@/views/statistics/ekRevenue/Articles.vue'),
  },
  //endregion
  //region Documents
  {
    path: '/documents',
    name: 'Dokumente',
    component: () => import('@/views/crm/Documents.vue'),
  },
  {
    path: '/business',
    name: 'Business',
    component: () =>
      import(
        /* webPackChunkName: "Business" */ '@/views/business/Business.vue'
      ),
  },
  {
    path: '/inventory',
    name: 'Inventur',
    component: () =>
      import(
        '@/views/inventory/Inventory.vue'
      )
  },
  {
    path: '/inventory/scan',
    name: 'Artikel Scan',
    component: () => import('@/components/inventory/ScanPage.vue')
  },
  
  //endregion
  //region Article
  {
    path: '/articles',
    name: 'Artikel',
    component: () =>
      import(
        /* webpackChunkName: "Artikelsuche" */ '@/views/article/Article.vue'
      ),
    meta: { title: 'Artikel', linkable: false },
  },
  {
    path: '/articles/:articleNumber',
    name: 'Artikeldetails',
    props: (route) => {
      const articleNumber = route.params.articleNumber

      return { articleNumber, tab: route.query.tab || null }
    },
    meta: {
      title: 'Artikel',
    },
    component: () =>
      import(
        /* webpackChunkName: "Artikel" */ '@/views/article/ArticleView.vue'
      ),
  },
  //endregion
  //region Task
  {
    path: '/task',
    name: 'Task',
    meta: { title: 'Aufgaben' },
    component: () =>
      import(/* webpackChunkName: "Task" */ '@/views/task/Task.vue'),
  },
  {
    path: '/task/new',
    name: 'Aufgabe erstellen',
    component: () =>
      import(/* webpackChunkName: "AddTask" */ '@/views/task/AddTask.vue'),
  },
  {
    path: '/task/:lfdnr',
    name: 'TaskDetails',
    props: (route) => {
      const lfdnr = Number.parseInt(route.params.lfdnr, 10)
      if (Number.isNaN(lfdnr)) {
        return 0
      }
      return { lfdnr }
    },
    meta: { title: 'Aufgabe #' },
    component: () => import('@/views/task/TaskDetailRedirect.vue'),
  },
  //endregion
  {
    path: '/dev',
    name: 'devPortal',
    meta: { title: 'Entwicklerportal' },
    component: () =>
      import(
        /* webpackChunkName: "devPortal" */ '@/views/devPortal/DevPortal.vue'
      ),
  },
  //region Settings
  {
    path: '/settings',
    meta: { title: 'Einstellungen', linkable: false },
  },
  {
    path: '/settings/crm',
    name: 'settings_crm',
    props: { app: 'crm', displayName: 'CRM' },
    meta: { title: 'CRM' },
    component: () => import('@/views/settings/Settings.vue'),
  },
  {
    path: '/settings/calv2',
    name: 'settings_calendar',
    props: { app: 'cal', displayName: 'Kalender' },
    meta: { title: 'Kalender' },
    component: () => import('@/views/settings/Settings.vue'),
  },
  {
    path: '/settings/task',
    name: 'settings_task',
    props: { app: 'task', displayName: 'Aufgaben' },
    meta: { title: 'Aufgaben' },
    component: () => import('@/views/settings/Settings.vue'),
  },
  //endregion
  {
    path: '/notifications',
    name: 'Notification',
    meta: { title: 'Benachrichtigungen' },
    component: () =>
      import(
        /* webpackChunkName: "" */ '@/views/notifications/NotificationView.vue'
      ),
  },
  {
    path: '/nopermission',
    name: 'Kein Zugriff',
    meta: { noAuthRequired: true },

    component: () => import('@/views/core/NoPerm.vue'),
  },
  {
    path: '*',
    name: 'Not Found',
    meta: { title: 'Nicht gefunden', noAuthRequired: true },

    component: () => import('@/views/core/404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  // Lädt refreshToken aus der URL
  // Dies ist notwendig um WEB von REV zu starten
  if (to.query.refreshToken) {
    await renewBearerWithRefreshTokenAndNavigateToPath(
      to.query.refreshToken,
      to.path,
      to.query
    )
  }

  const loggedIn = store.getters.loggedIn
  if (!to.matched[0].meta.noAuthRequired && !loggedIn) {
    next({ path: '/login', query: { redirectTo: to.path } })
  } else {
    next()
  }
})

router.afterEach(async (to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = getRouteTitle(to)
  })
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

export default router

export function getRouteTitle(route) {
  let title = ''

  if (route.meta.title) {
    title += route.meta.title
  } else {
    title += route.name
  }

  const searchInput = route.query.q

  switch (route.name) {
    case 'CRM':
      if (searchInput && searchInput !== '') {
        title += ` - "${searchInput}"`
      } else {
        title += ' - Suche'
      }
      break
    case 'crmAddress':
      if (route.params.name) {
        title += ` - ${route.params.name} (${route.params.lfdnr})`
      } else {
        title += ` - ${route.params.lfdnr}`
      }
      break
    case 'Kalender':
      if (route.params.weekNumber && route.params.weekNumber > 0) {
        title += ` - KW${route.params.weekNumber}`
      }
      break
    case 'crmAddressDocumentDetailed':
      if (route.params.name) {
        title += route.params.name
      } else {
        title += `${route.params.lfdnr}/${route.params.wf_cid}`
      }
      break
    case 'Artikel':
      if (searchInput && searchInput !== '') {
        title += ` - "${searchInput}"`
      } else {
        title += ' - Suche'
      }

      break
    case 'Artikeldetails':
      if (route.params.name) {
        title += ` - ${route.params.name} (${route.params.articleNumber})`
      } else {
        title += ` - ${route.params.articleNumber}`
      }
      break
    case 'TaskDetails':
      title += route.params.lfdnr
      break
    case 'settings_crm':
    case 'settings_calendar':
    case 'settings_task':
      title += ' - Einstellungen'
  }
  return title + ' · LBA-WEB'
}
