import store from '@/store/index.js'
import appointmentsImage from '@/assets/img/widgets/APPOINTMENTS.png'
import tasksImage from '@/assets/img/widgets/TASKS.png'
import telemetryFailedLoginsImage from '@/assets/img/widgets/TELEMETRY_FAILED_LOGINS.png'
import customersTodayImage from '@/assets/img/widgets/CUSTOMERS_TODAY.png'
import openPositionsImage from '@/assets/img/widgets/OPEN_POSITIONS.png'
import statisticMonthImage from '@/assets/img/widgets/STATISTICS_MONTH.png'
import statisticTurnoverImage from '@/assets/img/widgets/STATISTICS_TURNOVER.png'
import statisticYearImage from '@/assets/img/widgets/STATISTICS_YEAR.png'
import clockImage from '@/assets/img/widgets/CLOCK.png'
//import notificationsImage from '@/assets/img/widgets/NOTIFICATIONS.png'
import documentsLastImage from '@/assets/img/widgets/DOCUMENTS_LAST.png'
import interactionsLastImage  from '@/assets/img/widgets/INTERACTIONS_LAST.png'

export default {
  state: {
    availableWidgets: {
      Kalender: [
        {
          widgetID: 'APPOINTMENTS',
          displayName: 'Agenda',
          appId: 2,
          appName: 'Kalender',
          image: appointmentsImage,
          description:
            'Ihre nächsten Termine übersichtlich Zusammengefasst. Bietet die Möglichkeit die Termine direkt zu bearbeiten.',
        },
      ],
      Aufgaben: [
        {
          widgetID: 'TASKS',
          displayName: 'Offene Aufgaben',
          appId: 9,
          appName: 'Aufgaben',
          image: tasksImage,
          description: 'Listet Ihre offenen Aufgaben übersichtlich auf.',
        },
      ],
      Entwicklerportal: [
        {
          widgetID: 'TELEMETRY_FAILED_LOGINS',
          displayName: 'Fehlgeschlagene Logins 24h',
          appId: 6,
          appName: 'Entwicklerportal',
          image: telemetryFailedLoginsImage,
          description:
            'Gibt die Anzahl der fehlgeschlagenen Anmeldeversuche in den letzten 24 Stunden aus.',
        },
      ],
      Statistik: [
        {
          widgetID: 'CUSTOMERS_TODAY',
          displayName: 'Kunden heute',
          appId: 3,
          appName: 'Statistik',
          image: customersTodayImage,
          description: 'Zeigt die Anzahl der heutigen Kunden.',
        },
        {
          widgetID: 'OPEN_POSITIONS',
          displayName: 'Offene Posten',
          appId: 3,
          appName: 'Statistik',
          image: openPositionsImage,
          description:
            'Zeigt die Summen der offenen Posten, monatlich gruppiert.',
        },
        {
          widgetID: 'STATISTIC_MONTH',
          displayName: 'Monatsvergleich Umsatz',
          appId: 3,
          appName: 'Statistik',
          image: statisticMonthImage,
          description:
            'Vergleicht die Umsätze der letzten beiden Monate mit den Umsätzen des letzten Jahres.',
        },
        {
          widgetID: 'STATISTIC_TURNOVER',
          displayName: 'Aktuelle Umsätze',
          appId: 3,
          appName: 'Statistik',
          image: statisticTurnoverImage,
          description: 'Zeigt die aktuellen Tagesumsätze.',
        },
        {
          widgetID: 'STATISTIC_YEAR',
          displayName: 'Jahresumsatz',
          appId: 3,
          appName: 'Statistik',
          image: statisticYearImage,
          description:
            'Vergleicht die Umsätze der letzten 4 Jahre. Mit Klick auf den Balken eines Jahres, öffnet sich der Umsatzvergleich auf Monatsbasis.',
        },
      ],
      CRM: [
        {
          widgetID: 'CLOCK',
          displayName: 'Uhrzeit',
          appId: 1,
          appName: 'CRM',
          image: clockImage,
          description: 'Zeigt, die aktuelle Uhrzeit und Datum.',
        },
        {
          widgetID: 'NOTIFICATIONS',
          displayName: 'Benachrichtigungen',
          appId: 1,
          appName: 'CRM',
          image: null,
          description:
            'Gibt einen Überblick über die letzten Benachrichtigungen.',
        },
        {
          widgetID: 'DOCUMENTS_LAST',
          displayName: 'Zuletzt bearbeitete Dokumente',
          appId: 1,
          appName: 'CRM',
          image: documentsLastImage,
          description:
            'Gibt einen Überblick über die zuletzt bearbeiteten Dokumente. Ein Klick auf ein Dokument leitet zur Detailansicht des Dokuments weiter.',
        },
        {
          widgetID: 'INTERACTIONS_LAST',
          displayName: 'Neuste Interaktionen',
          appId: 1,
          appName: 'CRM',
          image: interactionsLastImage,
          description:
            'Gibt einen Überblick über die neuesten Interaktionen. Ein Klick auf eine Interaktion leitet auf die Adresse weiter.',
        },
      ],
    },
    minSizes: {
      xxs: {
        CLOCK: {
          minW: 1,
          minH: 5,
        },
        NOTIFICATIONS: {
          minW: 2,
          minH: 4,
        },
        TASKS: {
          minW: 2,
          minH: 5,
        },
        APPOINTMENTS: {
          minW: 3,
          minH: 4,
        },
        TELEMETRY_FAILED_LOGINS: {
          minW: 2,
          minH: 3,
        },
        CUSTOMERS_TODAY: {
          minW: 1,
          minH: 3,
        },
        OPEN_POSITIONS: {
          minW: 1,
          minH: 4,
        },
        STATISTIC_MONTH: {
          minW: 2,
          minH: 5,
        },
        STATISTIC_TURNOVER: {
          minW: 2,
          minH: 5,
        },
        STATISTIC_YEAR: {
          minW: 2,
          minH: 5,
        },
        DOCUMENTS_LAST: {
          minW: 2,
          minH: 3,
        },
        INTERACTIONS_LAST: {
          minW: 2,
          minH: 3,
        },
      },
      xs: {
        TASKS: {
          minW: 4,
          minH: 4,
        },
        APPOINTMENTS: {
          minW: 4,
          minH: 4,
        },
        CLOCK: {
          minW: 2,
          minH: 5,
        },
        NOTIFICATIONS: {
          minW: 3,
          minH: 4,
        },
        TELEMETRY_FAILED_LOGINS: {
          minW: 4,
          minH: 3,
        },
        CUSTOMERS_TODAY: {
          minW: 2,
          minH: 3,
        },
        OPEN_POSITIONS: {
          minW: 2,
          minH: 4,
        },
        STATISTIC_MONTH: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_TURNOVER: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_YEAR: {
          minW: 3,
          minH: 5,
        },
        DOCUMENTS_LAST: {
          minW: 4,
          minH: 3,
        },
        INTERACTIONS_LAST: {
          minW: 3,
          minH: 3,
        },
      },
      sm: {
        TASKS: {
          minW: 3,
          minH: 4,
        },
        APPOINTMENTS: {
          minW: 3,
          minH: 4,
        },
        CLOCK: {
          minW: 2,
          minH: 5,
        },
        NOTIFICATIONS: {
          minW: 3,
          minH: 4,
        },
        TELEMETRY_FAILED_LOGINS: {
          minW: 3,
          minH: 3,
        },
        CUSTOMERS_TODAY: {
          minW: 2,
          minH: 3,
        },
        OPEN_POSITIONS: {
          minW: 3,
          minH: 4,
        },
        STATISTIC_MONTH: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_TURNOVER: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_YEAR: {
          minW: 3,
          minH: 5,
        },
        DOCUMENTS_LAST: {
          minW: 4,
          minH: 3,
        },
        INTERACTIONS_LAST: {
          minW: 4,
          minH: 3,
        },
      },
      md: {
        TASKS: {
          minW: 4,
          minH: 3,
        },
        APPOINTMENTS: {
          minW: 4,
          minH: 4,
        },
        CLOCK: {
          minW: 2,
          minH: 5,
        },
        NOTIFICATIONS: {
          minW: 4,
          minH: 4,
        },
        TELEMETRY_FAILED_LOGINS: {
          minW: 4,
          minH: 3,
        },
        CUSTOMERS_TODAY: {
          minW: 3,
          minH: 3,
        },
        OPEN_POSITIONS: {
          minW: 3,
          minH: 4,
        },
        STATISTIC_MONTH: {
          minW: 4,
          minH: 5,
        },
        STATISTIC_TURNOVER: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_YEAR: {
          minW: 4,
          minH: 5,
        },
        DOCUMENTS_LAST: {
          minW: 5,
          minH: 3,
        },
        INTERACTIONS_LAST: {
          minW: 5,
          minH: 3,
        },
      },
      lg: {
        TASKS: {
          minW: 4,
          minH: 4,
        },
        APPOINTMENTS: {
          minW: 3,
          minH: 4,
        },
        CLOCK: {
          minW: 2,
          minH: 5,
        },
        NOTIFICATIONS: {
          minW: 3,
          minH: 4,
        },
        TELEMETRY_FAILED_LOGINS: {
          minW: 3,
          minH: 3,
        },
        CUSTOMERS_TODAY: {
          minW: 2,
          minH: 3,
        },
        OPEN_POSITIONS: {
          minW: 3,
          minH: 3,
        },
        STATISTIC_MONTH: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_TURNOVER: {
          minW: 3,
          minH: 5,
        },
        STATISTIC_YEAR: {
          minW: 3,
          minH: 5,
        },
        DOCUMENTS_LAST: {
          minW: 3,
          minH: 3,
        },
        INTERACTIONS_LAST: {
          minW: 4,
          minH: 3,
        },
      },
    },
  },
  getters: {
    minSizes: (state) => state.minSizes,
    permittedWidgets: (state)  => {
      let permittedWidgets = {}

      Object.keys(state.availableWidgets).forEach(appName => {
        const widgetsOfCategory = state.availableWidgets[appName]

        if(store.getters.userHasAppById(widgetsOfCategory[0].appId)) {
          permittedWidgets[appName] = widgetsOfCategory
        }
      })

      return permittedWidgets
    },
  },
}
