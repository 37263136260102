<template>
  <v-breadcrumbs :items="items" divider=">" large>
    <template #item="{ item }">
      <v-breadcrumbs-item :to="item.disabled ? '' : item.href" exact-path>
        <v-icon v-if="item.icon" color="primary">{{ item.icon }}</v-icon>
        &nbsp;
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  data() {
    return {
      allRoutes: [],
      items: [],
      path: '',
      paths: [],
    }
  },

  watch: {
    // Watch for route changes and rebuild breadcrumbs
    $route() {
      this.items = []
      this.buildBreadcrumbs()
    },
  },
  mounted() {
    this.buildBreadcrumbs()
  },

  methods: {
    buildBreadcrumbs() {
      let tempPath = ''
      let name = ''

      //Get All Routes
      this.initAllRoutes()

      //get current path
      this.currentPath = this.$route.path

      //get all subPaths in currentPath
      this.subPaths = this.currentPath.split('/')

      //Add Empty Home Path to items
      this.items.push({
        text: '',
        icon: 'mdi-home',
        href: '/',
        disabled: false,
      })

      //Start with 1 as we skip the root of the route
      for (let i = 1; i < this.subPaths.length; i++) {
        let path = this.subPaths[i]
        tempPath = tempPath + '/' + path

        // Try to find route by path
        const route = this.findRoute(tempPath)
        const lastPathElement = route.path.split('/').at(-1)

        if (route && lastPathElement.includes(':')) {
          name = this.getRouteDisplayName(route)
        } else if (route && route.name) {
          name = route.name
        } else {
          name = decodeURIComponent(path)
        }

        // Add item to breadcrumbs
        this.items.push({
          text: name,
          href: tempPath,
          disabled: route ? !route.linkable : true,
        })
      }
    },
    initAllRoutes() {
      //loop over all existing Routes
      this.$router.options.routes.forEach((route) => {
        //Get Route name and path recursively
        this.getRouteData(route)
      })
    },
    getRouteDisplayName(route) {
      // Extract dynamic parameters from the route path and replace them with actual values
      let displayName = route.path

      const paramMatches = route.path.match(/:([^/]+)/g) // Match all dynamic segments like :lfdnr, :wfc_id

      if (paramMatches) {
        paramMatches.forEach((param) => {
          const paramName = param.replace(/[:?]/g, '') // Remove param properties, e.g., :tab? -> tab
          const paramValue = this.$route.params[paramName] // Get the actual value from route params

          if (paramValue) {
            displayName = displayName.replace(param, paramValue) // Replace :lfdnr with 45, etc.
          }
        })
      }

      // Return the fully constructed display name with actual parameter values
      return displayName.split('/')[displayName.split('/').length - 1]
      //return displayName
    },

    findRoute(path) {
      // This method handles dynamic routes
      return this.allRoutes.find((route) => {
        if (!route.path || route.path === '*') return false // Skip invalid or wildcard routes

        try {
          const dynamicRoutePattern = new RegExp(
            `^${route.path.replace(/:[^/]+/g, '[^/]+')}$`
          )
          return dynamicRoutePattern.test(path)
        } catch (error) {
          console.error('Regex error in findRoute:', error)
          return false
        }
      })
    },

    getRouteData(route) {
      //If the root has children => recursion
      if ('children' in route) {
        route.children.forEach((subRoute) => {
          this.getRouteData(subRoute)
        })
      }

      //Define routeName and path
      let routeName = route.name
      let routePath = route.path
      let linkable = true

      //If route has a meta.title => Use title as name
      if ('meta' in route && 'title' in route.meta) {
        routeName = route.meta.title
        linkable =
          route.meta.linkable === undefined ? true : route.meta.linkable
      } else {
        linkable = true
      }

      //Add to allRoutes Array
      this.allRoutes.push({
        name: routeName,
        path: routePath,
        linkable: linkable,
      })
    },
  },
}
</script>
