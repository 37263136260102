<template>
  <v-card
    v-if="!noCard"
    flat
    :outlined="outlined"
    :class="bordered ? 'border-left-primary-3' : 'rounded-0'"
  >
    <slot name="image"></slot>
    <v-card-title class="text-break">
      <slot name="title">
        {{ title }}
      </slot>
    </v-card-title>

    <v-card-subtitle>
      <slot name="subtitle">
        {{ subtitle }}
      </slot>
    </v-card-subtitle>

    <v-card-text>
      <slot></slot>
      <slot name="content"> </slot>
    </v-card-text>

    <v-card-actions class="d-flex justify-end flex-grow-1 align-end">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
  <span v-else
    ><p class="text-h6" style="color: black">
      <slot name="title">
        {{ title }}
      </slot>
    </p>
    <slot></slot> <slot name="content"> </slot>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    noCard: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style>
.text-break {
  word-break: break-all;
}
</style>
