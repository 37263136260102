import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

// CORE
import auth from '@/store/modules/core/auth.js'
import company from '@/store/modules/core/company.js'
import apps from '@/store/modules/core/apps.js'
import user from '@/store/modules/core/user.js'

// Utils
import gender from '@/store/modules/util/gender.js'

// CRM
import crmAddresses from '@/store/modules/crm/addresses.js'
import crmInteractions from '@/store/modules/crm/interactions.js'
import crmDocuments from '@/store/modules/crm/documents.js'
import crmContacts from '@/store/modules/crm/contacts.js'
import crmCustomerGroups from '@/store/modules/crm/customerGroups.js'
import crmFilter from '@/store/modules/crm/filter.js'
import crmSettings from '@/store/modules/crm/crmSettings.js'

// STATISTICS
import statisticsWarehouses from '@/store/modules/statistics/warehouses.js'
import statisticsTimeFilter from '@/store/modules/statistics/timeFilter.js'
import distributors from '@/store/modules/statistics/distributors.js'
import statisticsRevenueTime from '@/store/modules/statistics/revenueTime.js'
import statisticsOffer from '@/store/modules/statistics/offer.js'
import statisticsCustomer from '@/store/modules/statistics/customer.js'
import vkCustomerRevenueStatistic from '@/store/modules/statistics/vkCustomerRevenue.js'
import filter from '@/store/modules/bi/filter.js'

// CAL
import calAppointments from '@/store/modules/calendar/appointments.js'
import calendarInformation from '@/store/modules/calendar/calendarInformation.js'

// Article
import articlePermissions from '@/store/modules/article/articlePermissions.js'
import articleFilter from '@/store/modules/article/filter.js'
import articleDetails from '@/store/modules/article/articleDetails.js'
import articleStatisticsCharts from '@/store/modules/article_statistics/charts.js'
import articleStatisticsDataTable from '@/store/modules/article_statistics/datatable.js'

// Business
import businessDocument from '@/store/modules/business/businessDocument.js'
import businessDocumentHead from '@/store//modules/business/businessDocumentHead.js'
import businessDocumentHeadExtended from '@/store/modules/business/businessDocumentHeadExtended.js'
import businessDocumentFoot from '@/store/modules/business/businessDocumentFoot.js'
import businessDocumentOverview from '@/store/modules/business/businessDocumentOverview'

// Settings
import masterData from '@/store/modules/settings/masterData.js'
import userSettings from '@/store/modules/settings/userSettings.js'

//Task
import task from '@/store/modules/task/task.js'

//Inventory
import inventory from '@/store/modules/inventory/inventory.js'

// Notifications
import notifications from '@/store/modules/notifications/notifications.js'

// Dashboard
import widgets from '@/store/modules/home/widgets.js'

// Statistics
import revenueStatistics from '@/store/modules/statistics_reloaded/revenueStatistics.js'
import revenueCategoryStatistics from '@/store/modules/statistics_reloaded/revenueCategoryStatistics.js'
import customerStatistics from '@/store/modules/statistics_reloaded/customerStatistics.js'
import distributorStatistics from '@/store/modules/statistics_reloaded/distributorStatistics.js'
import documentStatistics from '@/store/modules/statistics_reloaded/documentStatistics.js'
import customerfrequencyStatistics from '@/store/modules/statistics_reloaded/customerfrequencyStatistics.js'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    //CORE
    auth,
    company,
    apps,
    user,
    // UTILS
    gender,

    //calAppointments,
    crmAddresses,
    crmInteractions,
    crmContacts,
    crmCustomerGroups,
    crmDocuments,
    crmFilter,
    crmSettings,
    statisticsWarehouses,
    statisticsTimeFilter,
    statisticsRevenueTime,
    statisticsOffer,
    statisticsCustomer,
    articleDetails,
    articleFilter,
    articlePermissions,
    articleStatisticsCharts,
    articleStatisticsDataTable,
    businessDocument,
    businessDocumentHead,
    businessDocumentHeadExtended,
    businessDocumentFoot,
    businessDocumentOverview,
    notifications,
    calAppointments,
    calendarInformation,
    masterData,
    //userSettings,
    distributors,
    task,
    widgets,
    vkCustomerRevenueStatistic,
    filter,
    revenueStatistics,
    revenueCategoryStatistics,
    customerStatistics,
    distributorStatistics,
    documentStatistics,
    customerfrequencyStatistics,
    userSettings,
    // Inventory
    inventory
  },
})
