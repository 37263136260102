import axios from 'axios'

export async function getAddress(lfdnr) {
  return await axios.get(`/v1/crm/addresses/${lfdnr}`)
}

export async function getContactData(lfdnr) {
  return await axios.get(`/v1/crm/addresses/${lfdnr}/contactData`)
}

export async function getDocuments(lfdnr) {
  const response = await axios.get(`/v1/crm/addresses/${lfdnr}/documents`)

  return response.data
}

export async function loadAddressTypes() {
  //for displaying addressTypes in add address
  //includes fldName field
  const response = await axios.get('/v1/crm/addresses/new/addresstypes')

  return response.data
}

export async function checkAddressSVNR(svnr) {
  const response = await axios.post('/v1/crm/addresses/new/checksv', null, {
    params: {
      svnr,
    },
  })

  return response.data
}

export async function updateContactData(lfdnr, contactData) {
  const response = await axios.put(
    `/v1/crm/addresses/${lfdnr}/contactData`,
    contactData
  )
  return response.data
}

export async function adjustOpenDocuments(
  lfdnr,
  contactData,
  changeOpenDocuments
) {
  const response = await axios.post(
    `/v1/crm/addresses/${lfdnr}/openDocuments`,
    contactData,
    { params: { changeOpenDocuments: changeOpenDocuments } }
  )

  return response
}

export async function searchDoctors(searchObject) {
  const response = await axios.get('/v1/crm/addresses/new/doctorSearch', {
    params: {
      q: searchObject,
    },
  })

  return response.data
}

export async function searchAddressesFiltered(searchObject) {
  const response = await axios.post(
    '/v1/crm/addresses/search/filtered',
    searchObject
  )
  return response.data
}

export async function saveNewPerson(personObject) {
  return await axios.post('/v1/crm/addresses/new/person', personObject)
}

export async function saveNewNonPerson(nonPersonObject) {
  return await axios.post('/v1/crm/addresses/new', nonPersonObject)
}

export async function getAddressTypes() {
  const response = await axios.get('/v1/crm/addresses/types')
  return response.data
}

export async function getFields() {
  const response = await axios.get('/v1/crm/addresses/fields')
  return response.data
}

export async function getCustomerGroups() {
  const response = await axios.get('/v1/crm/addresses/groups')
  return response.data
}

export async function loadAddAddressOptions() {
  const response = await axios.get('/v1/crm/addresses/new/loadOptions')
  return response.data
}

export async function loadPatientOptions() {
  const response = await axios.get('/v1/crm/addresses/new/loadOptions/patient')
  return response.data
}

export async function loadEmployeeOptions() {
  const response = await axios.get('/v1/crm/addresses/new/loadOptions/employee')
  return response.data
}

export async function loadDoctorOptions() {
  const response = await axios.get('/v1/crm/addresses/new/loadOptions/doctor')
  return response.data
}

export async function loadCompanyOptions() {
  const response = await axios.get('/v1/crm/addresses/new/loadOptions/company')
  return response.data
}

export async function loadSupplierOptions() {
  const response = await axios.get('/v1/crm/addresses/new/loadOptions/supplier')
  return response.data
}

export async function loadHealthInsuranceCompanyOptions() {
  const response = await axios.get(
    '/v1/crm/addresses/new/loadOptions/healthInsuranceCompany'
  )
  return response.data
}

export async function checkForDuplicatePersons(address) {
  const response = await axios.post(
    '/v1/crm/addresses/new/duplicate/person',
    address
  )
  return response.data
}

export async function checkForDuplicateNonPersons(address) {
  const response = await axios.post('/v1/crm/addresses/new/duplicate', address)
  return response.data
}

export function getAddressTypeDetailed(addressType) {
  let type = {
    icon: 'mdi-help',
    name: addressType?.fldDisplayBez ?? 'Kein Adresstyp definiert',
  }

  switch (addressType?.bezeichnung ?? 'undef') {
    case 'Unternehmen':
      type.icon = 'fas fa-building'
      break
    case 'Lieferant':
      type.icon = 'mdi-dolly'
      break
    case 'Mitarbeiter':
      type.icon = 'mdi-cash-register'
      break
    case 'Arzt':
      type.icon = 'fas fa-user-md'
      break
    case 'Krankenkasse':
      type.icon = 'fas fa-hospital-user'
      break
    case 'Patient':
      type.icon = 'fas fa-user-injured'
      break
    case 'Interessent':
      type.icon = 'mdi-account-alert'
      break
    case 'Konsument':
      type.icon = 'fas fa-user'
      break
    case 'Sachwalter':
      type.icon = 'fas fa-handshake'
      break
    case 'Heime':
      type.icon = 'fas fa-home'
      break
    default:
      type.name = 'Kein Adresstyp definiert'
  }

  return type
}

export async function getDefaultValues() {
  const response = await axios.get('/v1/crm/addresses/new/loadOptions')
  return response.data
}

export async function getCountries() {
  const response = await axios.get('/v1/util/country')
  return response.data
}

export async function getCityForZip(zip, country) {
  const requestBody = {
    plz: zip,
    country,
  }
  const response = await axios.post('/v1/util/autofill/city', requestBody)
  return response.data
}

export async function getStreetNames(zip, query) {
  const requestBody = {
    plz: zip,
    query,
  }
  const response = await axios.post('/v1/util/autofill/street', requestBody)

  return response.data
}

export async function searchStreet(zip, query) {
  const options = {
    plz: zip,
    query,
  }
  const response = await axios.post('/v1/crm/addresses/new/street', options)

  return response.data
}

export function getCountryNameForId(countries, id) {
  for (let i = 0; i < countries.length; i++) {
    if (countries[i].id === id) {
      return countries[i].description
    }
  }
}

export function isIbanValid(iban) {
  const IBAN_MIN_SIZE = 15
  const IBAN_MAX_SIZE = 34
  const IBAN_MODULUS = 97

  const trimmed = iban.trim()

  if (trimmed.length < IBAN_MIN_SIZE || trimmed.length > IBAN_MAX_SIZE) {
    return false
  }

  const reformat = trimmed.slice(4) + trimmed.slice(0, 4)
  let total = 0

  for (let i = 0; i < reformat.length; i++) {
    const charValue = parseInt(reformat.charAt(i), 36)

    if (charValue < 0 || charValue > 35) {
      return false
    }

    total = (charValue > 9 ? total * 100 : total * 10) + charValue

    if (total > 999999999) {
      total = total % IBAN_MODULUS
    }
  }

  return total % IBAN_MODULUS === 1
}

export async function getAddressInteractions(lfdnr) {
  const response = await axios.get(`/v1/crm/addresses/${lfdnr}/interactions`)

  return response.data
}

export async function getAddressSubjects() {
  const response = await axios.get('/v1/crm/addresses/interactions/subjects')

  return response.data
}

export async function AddAddressInteraction(lfdnr, interaction) {
  const response = await axios.post(
    `/v1/crm/addresses/${lfdnr}/interactions`,
    interaction
  )

  return response.data
}

export async function editAddressInteraction(lfdnr, interaction) {
  const response = await axios.put(
    `/v1/crm/addresses/${lfdnr}/interactions`,
    interaction
  )

  return response.data
}

export async function deleteAddressInteraction(lfdnr, interaction) {
  const response = await axios.delete(
    `/v1/crm/addresses/${lfdnr}/interactions`,
    {
      data: interaction,
    }
  )

  return response.data
}

export async function getClaimPeriods(ownContributionGroup) {
  const response = await axios.get('/v1/crm/addresses/new/claimPeriods', {
    params: {
      ownContributionGroup,
    },
  })

  return response.data
}


