<template>
  <v-autocomplete
    v-model="document.customerId"
    :items="searchOutputs"
    :loading="searchOutputSearchLoading"
    :no-data-text="getCorrectNoDataText()"
    :search-input.sync="searchInputSync"
    dense
    item-text="name"
    item-value="id"
    label="LBA durchsuchen (mind. 3 Zeichen)"
    loading-text="Globale Suche läuft"
    no-filter
    outlined
    prepend-inner-icon="mdi-magnify"
  >
    <template #item="{ item }">
      <v-list-item @click="openSearchItem(item)">
        <v-avatar v-if="item.type === 'ADDRESS'" class="primary" size="36">
          <v-icon color="white">mdi-account-outline</v-icon>
        </v-avatar>
        <v-avatar v-if="item.type === 'ARTICLE'" class="primary" size="36">
          <v-icon color="white">mdi-archive-outline</v-icon>
        </v-avatar>
        <v-avatar
          v-if="item.type === 'DOCUMENT'"
          class="primary"
          label
          size="36"
        >
          <v-icon color="white">mdi-file-document-outline</v-icon>
        </v-avatar>
        <v-list-item-content class="ml-4">
          <v-list-item-title>
            <span v-if="item.type === 'DOCUMENT'">
              {{
                item.object.shortType +
                ' ' +
                item.name +
                ' / ' +
                getShortGermanDate(item.object.date) +
                ' (' +
                item.object.state +
                ')'
              }}
            </span>

            <span v-else-if="item.type === 'ADDRESS'">
              {{
                item.name +
                ', ' +
                item.object.kdStrasse +
                ', ' +
                item.object.kdPlz +
                ' ' +
                item.object.kdOrt
              }}
            </span>

            <span v-else-if="item.type === 'ARTICLE'">
              {{ item.name + ' (' + item.object.art_nr + ')' }}
            </span>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>mdi-arrow-right</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'
import { getShortGermanDate } from '@/services/calendar/dateTimeService.js'
import { getSearchResults } from '@/services/core/searchService.js'

export default {
  data() {
    return {
      customerFullTextSearchUrl: '/v1/dashboard/search',
      searchInputSync: '',
      searchOutputSearchLoading: false,
      searchOutputs: [],
      controller: undefined,
    }
  },
  computed: {
    ...mapState({
      document: (state) => state.businessDocument.document,
    }),
  },
  watch: {
    async searchInputSync(newValue) {
      if (newValue === '') {
        this.searchOutputs = []
      }
      await this.searchTrigger()
    },
  },
  methods: {
    getCorrectNoDataText() {
      if (this.searchInputSync == null || this.searchInputSync.length === 0) {
        return 'Suchbegriff eingeben, um die Suche zu starten.'
      }
      if (this.searchInputSync.length > 0 && this.searchInputSync.length < 3) {
        return 'Geben Sie mindestens 3 Zeichen ein, um die Suche zu starten.'
      }
      if (this.searchOutputSearchLoading) {
        return 'Ergebnisse werden geladen...'
      } else {
        return 'Keine Einträge gefunden.'
      }
    },
    initiateSearchUI() {
      this.searchOutputSearchLoading = true
      this.searchOutputs = []
    },
    async searchFullText(query) {
      if (query === undefined) {
        return
      }

      this.initiateSearchUI()

      if (typeof this.controller != typeof undefined) {
        this.controller.abort('Operation canceled due to new request.')
      }

      this.controller = new AbortController()

      await getSearchResults(
        this.customerFullTextSearchUrl,
        query,
        this.controller
      )
        .then((response) => {
          this.searchOutputs = response
          this.searchOutputSearchLoading = false
        })
        .catch((err) => {
          if (err.name !== 'CanceledError') {
            console.error('error searching address: ' + err)
          }
        })
    },
    async searchTrigger() {
      if (
        this.searchInputSync != null &&
        this.searchInputSync !== '' &&
        String(this.searchInputSync).length > 2
      ) {
        this.searchStartedOnce = true
        await this.searchFullText(this.searchInputSync)
      }
    },
    openSearchItem(item) {
      switch (item.type) {
        case 'ADDRESS':
          this.$router.push({ name: 'crmAddress', params: { lfdnr: item.id, name: item.name } })

          break
        case 'DOCUMENT':
          this.$router.push({
            name: 'crmAddressDocumentDetailed',
            params: {
              lfdnr: item.object.addressLfdnr,
              wf_cid: item.object.wf_cid,
              name: item.object.shortType + " " + item.name
            },
          })
          break
        case 'ARTICLE':
          this.$router.push({
            name: 'Artikeldetails',
            params: { articleNumber: item.id, name: item.name },
          })
          break
        default:
          alert('Dieses Feature muss noch implementiert werden.')
      }
    },
    getShortGermanDate,
  },
}
</script>
