<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ attrs }">
      <v-text-field
        v-if="iconPosition == 'right'"
        v-model="dateFormatted"
        :dense="dense"
        outlined
        background-color="white"
        :label="label"
        :append-icon="icon"
        :rules="ruleSet"
        v-bind="attrs"
        :disabled="disabled"
        @click:append="menu = true"
        @blur=";(date = parseDate(dateFormatted)), datePicked()"
      ></v-text-field>
      <v-text-field
        v-else-if="iconPosition == 'left'"
        v-model="dateFormatted"
        :dense="dense"
        outlined
        background-color="white"
        :label="label"
        :prepend-icon="icon"
        :rules="ruleSet"
        :disabled="disabled"
        v-bind="attrs"
        @click:prepend="menu = true"
        @blur=";(date = parseDate(dateFormatted)), datePicked()"
      ></v-text-field>
      <v-text-field
        v-else
        v-model="dateFormatted"
        :dense="dense"
        outlined
        background-color="white"
        :label="label"
        :rules="ruleSet"
        :disabled="disabled"
        v-bind="attrs"
        @click="menu = true"
        @blur=";(date = parseDate(dateFormatted)), datePicked()"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      color="primary"
      scrollable
      :min="minimumDate"
      :max="maximumDate"
      @change="emitChange()"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Abbrechen</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date), datePicked()">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { isValidDate } from '@/services/calendar/dateTimeService.js'

export default {
  props: {
    value: {
      type: [String, Date],
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Datum wählen',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    //TODO: add dateFormat property
    icon: {
      type: String,
      default: 'mdi-calendar',
    },
    iconPosition: {
      type: String,
      default: 'right',
    },
    minimumDate: {
      type: [String, Date],
      default: null,
    },
    maximumDate: {
      type: [String, Date],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedDate: '',
      date: '',
      dateFormatted: null,
      menu: false,
    }
  },
  computed: {
    ruleSet() {
      // Combine the legalDate rule with the custom rules provided via the :rules prop
      return [this.legalDate, ...this.rules]
    },
  },
  watch: {
    value(newValue) {
      this.date = newValue // Update selectedDate when the value prop changes
    },
    date() {
      this.dateFormatted = this.formatDate(this.date)
      this.$emit('input', this.date)
    },
  },
  mounted() {
    // Initialize selectedDate when the component is mounted
    this.date = this.value
  },
  methods: {
    datePicked() {
      const valid = this.ruleSet.every(
        (rule) => rule(this.formatDate(this.date)) === true
      )

      if (!valid) return

      this.$emit('datePicked', this.date)
    },
    parseDate(date) {
      if (!date) return null
      if (date.includes('.')) {
        return this.parseDottedDate(date)
      } else {
        return this.parseShortDate(date)
      }
    },
    parseDottedDate(date) {
      //e.g.: 13.01.1990
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    parseShortDate(date) {
      //e.g.: 13011990
      const day = date.slice(0, 2)
      const month = date.slice(2, 4)
      const year = date.slice(4)

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatDate(date) {
      if (!date) return ''

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    legalDate(value) {
      // Handle null or undefined value
      if (value === null || value === undefined) {
        return 'Kein gültiges Datum!'
      }

      if (value == '') {
        return true
      }

      const [day, month, year] = value.split('.')

      return isValidDate(day, month, year) || 'Kein gültiges Datum!'
    },
    emitChange() {
      const valid = this.ruleSet.every(
        (rule) => rule(this.formatDate(this.date)) === true
      )
      if (valid) this.$emit('input', this.date)
    },
  },
}
</script>
