import axios from 'axios'

export default {
  state: {
    scopes: undefined,
    types: undefined,
    locations: undefined,
    states: undefined,
    addresses: [],
    objects: [],
    resources: [],
    calDocuments: [],
  },
  mutations: {
    SET_SCOPES(state, scopes) {
      state.scopes = scopes
    },
    SET_TYPES(state, types) {
      state.types = types
    },
    SET_LOCATIONS(state, locations) {
      state.locations = locations
    },
    SET_STATES(state, states) {
      state.states = states
    },
    SET_ADDRESSES(state, addresses) {
      state.addresses = addresses
    },
    SET_OBJECTS(state, objects) {
      state.objects = objects
    },
    SET_RESOURCES(state, resources) {
      state.resources = resources
    },
    SET_CAL_DOCUMENTS(state, documents) {
      state.calDocuments = documents
    },
  },
  actions: {
    async loadScopes({ commit }) {
      return axios.get('/v1/calv2/information/scopes').then((data) => {
        commit('SET_SCOPES', data.data)
      })
    },
    async loadTypes({ commit }) {
      return axios.get('/v1/calv2/information/types').then((data) => {
        commit('SET_TYPES', data.data)
      })
    },
    async loadLocations({ commit }) {
      return axios.get('/v1/calv2/information/locations').then((data) => {
        commit('SET_LOCATIONS', data.data)
      })
    },
    async loadStates({ commit }) {
      return axios.get('/v1/calv2/information/states').then((data) => {
        commit('SET_STATES', data.data)
      })
    },
    async loadAddresses({ commit }, searchText) {
      return axios
        .get('/v1/calv2/information/addressSearch', {
          params: {
            q: searchText,
          },
        })
        .then((data) => {
          commit('SET_ADDRESSES', data.data)
        })
    },
    async loadObjects({ commit }, searchText) {
      return axios
        .get('/v1/calv2/information/objects', {
          params: {
            q: searchText,
          },
        })
        .then((data) => {
          commit('SET_OBJECTS', data.data)
        })
    },
    async loadResources({ commit }) {
      return axios.get('/v1/calv2/information/resources').then((data) => {
        commit('SET_RESOURCES', data.data)
      })
    },
    async loadCalDocuments({ commit }, appointment) {
      return axios
        .post('/v1/calv2/information/documents', appointment)
        .then((data) => {
          commit('SET_CAL_DOCUMENTS', data.data)
        })
    },
    setCalDocuments({ commit }, documents) {
      commit('SET_CAL_DOCUMENTS', documents)
    },
  },
  getters: {
    locations(state) {
      return state.locations
    },
    scopes(state) {
      return state.scopes
    },
  },
}
