export default {
  state: {
    filter: {
      from: '',
      to: '',
      location: [],
    },
  },
  mutations: {
    SET_DEFAULT_FILTER_DATA(state) {
      state.filter = {
        from: '',
        to: '',
        location: [],
      }
    },
  },
  actions: {
    setDefaultCustomerfrequencyFilterData({ commit }) {
      commit('SET_DEFAULT_FILTER_DATA')
    },
  },
}
