export default {
    state: {
        inventories: [],
        locations: [],
        selectedLocation: null,
        selectedInventory: null,
        currentArticle: null,
        lastScannedQueue: [],
    },
    mutations: {
      SET_INVENTORIES(state, inventories) {
        state.inventories = inventories
      },
      SET_LOCATIONS(state, locations){
        state.locations = locations
      },
      SET_SELECTED_LOCATION(state, location){
        state.selectedLocation = location
      },
      SET_SELECTED_INVENTORY(state, inventory){
        state.selectedInventory = inventory
      },
      SET_CURRENT_ARTICLE(state, article){
        state.currentArticle = article
      },
      SET_NEW_QUEUE_ENTRY(state, article){
        if(state.lastScannedQueue.length >= 5){ //queue implementation, sorted in reverse with newest first and oldest last
          state.lastScannedQueue.pop() //pop last
        }
        state.lastScannedQueue.unshift(article) //add to front
      }
    },
    actions: {
      setInventories({ commit }, newInventories) {
        commit('SET_INVENTORIES', newInventories)
      },
      setLocations({ commit }, newLocations) {
        commit('SET_LOCATIONS', newLocations)
      },
      setSelectedLocation({commit}, selectedLocation){
        commit('SET_SELECTED_LOCATION', selectedLocation)
      },
      setSelectedInventory({commit}, selectedInventory){
        commit('SET_SELECTED_INVENTORY', selectedInventory)
      },
      setCurrentArticle({commit}, newArticle){
        commit('SET_CURRENT_ARTICLE', newArticle)
      },
      addScannedArticle({commit}, newEntry){
        commit('SET_NEW_QUEUE_ENTRY', newEntry)
      }
    },
    getters: {
      getInventories(state) {
        return state.inventories
      },
      getLocations(state) {
        return state.locations
      },
      getSelectedLocation(state){
        return state.selectedLocation
      },
      getSelectedInventory(state){
        return state.selectedInventory
      },
      getCurrentArticle(state){
        return state.currentArticle
      },
      getLastScannedQueue(state){
        return state.lastScannedQueue
      }
    },
  }